var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[(_vm.qrView)?_c('div',{staticClass:"qr-page-wrapper"},[_c('VueText',{staticClass:"info",attrs:{"sizeLevel":"12","color":"white-100","weightLevel":"6"}},[_vm._v(" Raf üzerinde bulunan QR kodu okutunuz ")]),_vm._m(0),_c('div',{staticClass:"progress-wrapper"},[_c('VueText',{staticClass:"progress-title",attrs:{"sizeLevel":"9","color":"white-100"}},[_vm._v(" Tarama süreci ")]),_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar",style:({
            width: _vm.percent,
          })})])],1)],1):_vm._e(),_c('input',{ref:"fileInput",attrs:{"type":"file","name":"image","hidden":"true","capture":"environment"},on:{"change":_vm.getPhoto,"click":_vm.closeModal}}),(!_vm.qrView)?_c('div',{staticClass:"racks content-layout-fixer"},[_c('VueWrapperNarrow',[_c('div',{staticClass:"top-wrapper"},[_c('div',{staticClass:"store-info"},[_c('VueText',{staticClass:"title",attrs:{"sizeLevel":"6","color":"grey-30","weightLevel":"3"}},[_vm._v("Mağaza Adı")]),_c('VueText',{attrs:{"sizeLevel":"9","color":"grey-40","weightLevel":"3"}},[_vm._v(_vm._s(this.customerName)+" ")]),_c('VueText',{class:( _obj = { greenText: _vm.greenText }, _obj['total'] = true, _obj ),attrs:{"sizeLevel":"6","color":"grey-30","weightLevel":"3"}},[_vm._v("Toplam Raf Sayısı: "+_vm._s(this.rackCount))]),_c('VueText',{class:{ greenText: _vm.greenText },attrs:{"sizeLevel":"6","color":"grey-30","weightLevel":"3"}},[_vm._v("Fotoğraf Çekilen Raf Sayısı: "+_vm._s(this.racks.length))])],1),_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar",style:({
              width: _vm.percent,
              backgroundImage: _vm.bgImage,
            })})]),_c('div',{staticClass:"rack-list"},[_vm._l((this.rackImages),function(image){return _c('RackImage',{key:image.id,attrs:{"data":image},on:{"removeImage":_vm.removeImage,"changeImage":_vm.changeImage}})}),(!_vm.maxCount)?_c('div',{staticClass:"get-photo-row",on:{"click":function($event){return _vm.takePhoto()}}},[(this.rackImages.length < 2)?_c('div',[_c('div',{staticClass:"cam-icon-wrapper"},[_c('VueIcon',{attrs:{"iconName":"IconCameraBig","width":33,"height":26}})],1),_c('VueText',{attrs:{"sizeLevel":"9","weightLevel":"3"}},[_vm._v("Fotoğraf Çek")])],1):_vm._e()]):_vm._e()],2)]),_c('BrandButton',{attrs:{"disabled":_vm.disabledButton,"size":_vm.sizes.xxLarge,"contentAlignment":_vm.constants.flexAlignment.center,"type":"submit"},on:{"click":_vm.checkSkus}},[_vm._v(" RAFI TAMAMLA")])],1)],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"qr-box"},[_c('span'),_c('span'),_c('span'),_c('span'),_c('div',{staticClass:"dce-video-container"}),_c('div',{staticClass:"dce-scanarea"},[_c('div',{staticClass:"dce-scanlight"})])])}]

export { render, staticRenderFns }